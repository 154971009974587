
import Vue, { type PropType } from "vue"
import {
  CopilotMissingFieldComponent,
  CopilotMissingFieldName,
  CopilotMissingFieldsLabels,
  CopilotMissingFieldType,
  type CamerasByExid,
  type CopilotMissingField,
  type ProjectsByExid,
} from "@evercam/shared/types"
import CopilotFieldDateTime from "@evercam/shared/components/copilot/CopilotFieldDateTime.vue"
import CopilotFieldSwitch from "@evercam/shared/components/copilot/CopilotFieldSwitch.vue"
import CopilotFieldSchedule from "@evercam/shared/components/copilot/CopilotFieldSchedule.vue"
import CopilotFieldSelect from "@evercam/shared/components/copilot/CopilotFieldSelect.vue"
import CopilotFieldMultiOptions from "@evercam/shared/components/copilot/CopilotFieldMultiOptions.vue"
export default Vue.extend({
  name: "CopilotMissingFieldsForm",
  components: {
    CopilotFieldDateTime,
    CopilotFieldSwitch,
    CopilotFieldSelect,
    CopilotFieldSchedule,
    CopilotFieldMultiOptions,
  },
  props: {
    messageId: {
      type: [String, Number],
      required: true,
    },
    missingFields: {
      type: Array as PropType<CopilotMissingField[]>,
      required: true,
    },
    projectsByExid: {
      type: Object as PropType<ProjectsByExid>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    projectExid: {
      type: String,
      default: "",
    },
    cameraExid: {
      type: String,
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      missingFieldsLabels: CopilotMissingFieldsLabels,
      values: this.missingFields.reduce((acc, field) => {
        acc[field.name] = field.value

        return acc
      }, {} as Record<string, any>),
      componentsByName: {
        [CopilotMissingFieldName.ProjectExid]:
          CopilotMissingFieldComponent.Select,
        [CopilotMissingFieldName.CameraExid]:
          CopilotMissingFieldComponent.Select,
        [CopilotMissingFieldName.FromDate]:
          CopilotMissingFieldComponent.DateTime,
        [CopilotMissingFieldName.ToDate]: CopilotMissingFieldComponent.DateTime,
        [CopilotMissingFieldName.Schedule]:
          CopilotMissingFieldComponent.Schedule,
        [CopilotMissingFieldName.Duration]:
          CopilotMissingFieldComponent.MultiOptions,
      } as Record<string, CopilotMissingFieldComponent>,
      componentsByType: {
        [CopilotMissingFieldType.Boolean]: CopilotMissingFieldComponent.Switch,
      } as Record<string, CopilotMissingFieldComponent>,
    }
  },
  computed: {
    projects(): { title: string; value: string; image: string }[] {
      return Object.entries(this.projectsByExid).map(([key, value]) => ({
        title: value.name,
        value: key,
        image: this.getProjectThumbnailUrl(key),
      }))
    },
    cameras(): { title: string; value: string; image: string }[] {
      const allCameras = Object.entries(this.camerasByExid).map(
        ([key, value]) => ({
          title: value.name,
          value: key,
          image: this.getCameraThumbnailUrl(value.thumbnailUrl),
        })
      )

      if (!this.values?.projectExid && !this.projectExid) {
        return allCameras
      }

      return allCameras.filter(
        (camera) =>
          this.camerasByExid[camera.value].project.id ===
          (this.values.projectExid ?? this.projectExid)
      )
    },
    startDate(): string | undefined {
      if (!this.values?.projectExid) {
        return
      }

      return this.camerasByExid[this.values.projectExid!]?.createdAt
    },
    durationOptions() {
      return [
        {
          label: "30s",
          value: 30,
        },
        {
          label: "60s",
          value: 60,
        },
        {
          label: "90s",
          value: 90,
        },
      ]
    },
    missingFieldsProjectExid(): boolean {
      return (
        Object.keys(this.values).includes("projectExid") &&
        !this.values.projectExid
      )
    },
  },
  methods: {
    getComponentType(fieldName: string, fieldType: string) {
      return (
        this.componentsByName[fieldName] ||
        this.componentsByType[fieldType] ||
        null
      )
    },
    onSubmit() {
      this.$emit("missing-fields-completed", {
        id: this.messageId,
        missingFields: this.values,
      })
    },
    getProjectThumbnailUrl(projectExid: string) {
      return this.$imgproxy.get360pResizedImageUrl(
        `${this.$config.public.apiURL}/projects/${projectExid}/thumbnail?authorization=${this.token}`
      )
    },
    getCameraThumbnailUrl(thumbnailUrl: string) {
      return this.$imgproxy.get360pResizedImageUrl(
        `${thumbnailUrl}?authorization=${this.token}`
      )
    },
  },
})
